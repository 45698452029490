<script setup lang="ts">
const props = defineProps<PropTypes>();

const emit = defineEmits<EmitTypes>();

function handleToggleTap(toggleKey: string) {
  emit("toggle-tap", toggleKey);
}

type ToggleType = {
  key: string;
  name: string;
  checked?: boolean;
};

type PropTypes = {
  designToggles: Record<string, ToggleType>;
};

type EmitTypes = {
  (e: "toggle-tap", toggleKey: string): void;
};
</script>

<template>
  <section class="grid grid-cols-1 gap-2 xs:grid-cols-2 md:!grid-cols-3">
    <button
      :class="{
        'ring-1 ring-theme-accent-500 ring-opacity-100': toggle.checked,
      }"
      class="relative p-4 text-left border rounded-md text-dynamic-neutral-800 dark:text-dynamic-neutral-100 border-dynamic-neutral-200 dark:border-dynamic-neutral-700"
      @click="handleToggleTap(toggle.key)"
      v-for="(toggle, key) in props.designToggles"
      :key="key"
    >
      <h4>{{ toggle.name }}</h4>
      <i
        v-if="toggle.checked"
        class="absolute text-theme-accent-500 top-4 right-4 fas fa-check-circle"
      ></i>
    </button>
  </section>
</template>
