import api from "@/services/api";

export function getNotifications() {
  return api.get<{ notifications: Array<UserNotification> }>("/notifications");
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function clearNotifications(_notificationIds: string[] = []) {
  return api.post<{ notifications: Array<UserNotification> }>(
    "/notifications/clear"
  );
}

export function getUnreadNotifications() {
  return api.get("/unread-notifications");
}
