import { acceptHMRUpdate, defineStore } from "pinia";
import { computed, ref } from "vue";

import useAuth from "@/stores/common/auth";
import useFollowStore from "@/stores/resources/follow-store";
import useNotificationStore from "@/stores/resources/notifications-store";
import useUserStore from "@/stores/resources/users-store";

import usePageState from "@/hooks/usePageState";

import type { InitialRequestData } from "@/services/app-service";
import { loadExplorePageData } from "@/services/page-service";

const useExplorePageStore = defineStore("explore-page-store", function () {
  const { fetchPageData, loading, errorWhileLoading } = usePageState();

  const followStore = useFollowStore();
  const auth = useAuth();
  const userStore = useUserStore();

  const userIds = ref<Set<number>>(new Set());

  const notifications = useNotificationStore();

  const users = computed(() =>
    Array.from(userIds.value)
      .map((userId) => userStore.usersById[userId])
      .filter((u) => !!u)
  );

  function init(data: InitialRequestData) {
    userIds.value = new Set(
      data.activities.explore.user_ids.filter((id) => auth.user.id !== id)
    );
  }

  function setUserIds(users: Array<User>) {
    userIds.value = new Set(users.map((u) => u.id));
  }

  /**
   * Handles button click event in UserFollowCard component.
   * @description Follows or unfollows the user based on the current state.
   *
   * @param username Username of the user to follow/unfollow.
   * @return
   */
  function handleFollowButtonClick(username: string) {
    // Is auth user following the username? unfollow, else follow.
    const followCheck =
      !!followStore.usersFollowingsByUsername[auth.user.username]?.has(
        username
      );
    console.log({
      followCheck,
    });
    followCheck
      ? followStore.unfollowUser(username)
      : followStore.followUser(username);
  }

  async function loadPageData() {
    await fetchPageData({
      async fetch() {
        await new Promise((resolve) => setTimeout(() => resolve(0), 1000));
        const { data } = await loadExplorePageData();
        userIds.value = new Set([
          ...Array.from(userIds.value),
          ...data.users.map((u) => u.id),
        ]);
        userStore.parseUsers(data.users);
        notifications.parseNotificatiosFromRequestData(data);

        followStore.parseFollowings(data.following);
        followStore.parseFollowers(data.followers);

        setUserIds(data.users);
      },
    });
  }

  function isAuthFollowingUser(user: User) {
    return !!followStore.usersFollowingsByUsername[auth.user.username]?.has(
      user.username
    );
  }

  function isUserFollowingAuth(user: User) {
    return !!followStore.userFollowersByUsername[auth.user.username]?.has(
      user.username
    );
  }

  return {
    loading,
    errorWhileLoading,
    loadPageData,
    handleFollowButtonClick,
    users,
    isAuthFollowingUser,
    isUserFollowingAuth,
    init,
  };
});

export default useExplorePageStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useExplorePageStore, import.meta.hot));
}
