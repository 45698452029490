<script lang="ts" setup>
import useNotificationStore from "@/stores/resources/notifications-store";

enum NotificationCounterTypes {
  UNREAD_MESSAGES,
  UNREAD_MENTIONS_AND_REACTIONS,
  NONE,
}

// eslint-disable-next-line vue/define-macros-order
const props = withDefaults(defineProps<PropsType>(), {
  isAlwaysFloating: false,
  isFloatingMobile: false,
  hasShadow: false,
  type: NotificationCounterTypes[
    NotificationCounterTypes.UNREAD_MENTIONS_AND_REACTIONS
  ] as keyof typeof NotificationCounterTypes,
});

const notificationStore = useNotificationStore();

type PropsType = {
  isAlwaysFloating?: boolean;
  isFloatingMobile?: boolean;
  hasShadow?: boolean;
  type?: NotificationCounterType;
};

type NotificationCounterType = keyof typeof NotificationCounterTypes;
</script>

<template>
  <span
    v-if="
      notificationStore.totalUnreadCount > 0 &&
      type ===
        NotificationCounterTypes[
          NotificationCounterTypes.UNREAD_MENTIONS_AND_REACTIONS
        ]
    "
    :class="{
      'shadow-md shadow-black/40 dark:shadow-black/75': props.hasShadow,
      'absolute -top-3 -right-3.5': props.isAlwaysFloating,
      'absolute -top-3 -right-3.5 md:relative md:top-auto md:right-auto':
        !props.isAlwaysFloating && props.isFloatingMobile,
    }"
    class="flex flex-col items-center justify-center w-6 h-6 font-sans text-sm font-bold scale-95 rounded-full shadow-md bg-theme-500 text-theme-aware-neutral-500"
    ><p>
      {{
        notificationStore.totalUnreadCount > 9
          ? "9+"
          : notificationStore.totalUnreadCount
      }}
    </p></span
  >
</template>
@/stores/resources/notifications-store
