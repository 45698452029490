<script lang="ts" setup>
import NotificationTemplate from "@/components/NotificationTemplate.vue";

import { ROUTES } from "@/router";

const props = withDefaults(defineProps<PropTypes>(), {
  compact: true,
});

console.log({
  props,
});

type PropTypes = {
  user: User | undefined;
  postId: number;
  postText: string;
  compact?: boolean;
  readableDate?: string;
};
</script>

<template>
  <NotificationTemplate
    :routeNavigationOptions="{
      name: ROUTES.POST_DETAIL,
      params: { postId: props.postId },
    }"
  >
    <template #left>
      <i class="text-xl text-theme-accent-500 fas fa-at" />
    </template>

    <template #default>
      <div class="h-auto text-sm">
        <span
          class="font-semibold text-dynamic-neutral-700 dark:text-dynamic-neutral-200"
          >{{ props.user ? props.user.name : "Someone" }}</span
        >
        &nbsp;<span
          class="text-dynamic-neutral-500 dark:text-dynamic-neutral-400"
          >mentioned you:</span
        >
      </div>

      <p
        v-if="!compact"
        class="pt-2 text-dynamic-neutral-700 dark:text-dynamic-neutral-100"
      >
        {{ props.postText }}
      </p>

      <p
        class="pt-2 text-sm font-medium text-dynamic-neutral-500 dark:text-dynamic-neutral-400"
      >
        {{ readableDate }}
      </p>
    </template>
  </NotificationTemplate>
</template>
