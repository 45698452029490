<script lang="ts" setup>
import { ProfilePhoto } from "@allaxis/ui";
import { computed } from "vue";

import useAuthStore from "@/stores/common/auth";

import useAsset from "@/hooks/useAsset";

const props = defineProps<PropTypes>();

const authStore = useAuthStore();
const currentUser = authStore.user;

const asset = useAsset();

const imageUrl = computed(() =>
  asset(
    "profile/hd/" + props.user.profile_photo?.filename,
    !!props.user.profile_photo
  )
);

type PropTypes = {
  user: User;
};
</script>

<template>
  <div
    class="flex flex-col items-center justify-center w-full h-64 p-4 space-y-3"
  >
    <ProfilePhoto
      v-if="user"
      :image-url="imageUrl"
      :placeholder-text="user.acronym"
      size="lg"
    />
    <h1 class="text-lg font-semibold">{{ user.name }}</h1>
    <h2 class="text-md">@{{ user.username }}</h2>
    <div class="flex flex-row space-x-10">
      <span>{{ user.post_count }}</span>
      <span>{{ user.follower_count }}</span>
      <span>{{ user.following_count }}</span>
    </div>
    <p v-if="currentUser.id !== user.id">
      This is the beginning of a nice conversation with {{ user.name }}
    </p>
    <p v-else>Feel free to use this as a note to self...</p>
  </div>
</template>
@/stores/common/auth
