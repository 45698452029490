import api from "@/services/api";

export async function getUserFollowing(username: string) {
  return api.get<Array<number>>(`/users/${username}/following/`);
}

export async function getUserFollowers(username: string) {
  return api.get<Array<number>>(`/users/${username}/followers/`);
}

export async function followUserByUsername(username: string) {
  return api.post<{ follower: User; following: User }>(
    `/users/${username}/follow/`
  );
}

export async function unfollowUserByUsername(username: string) {
  return api.delete<{ unfollowed: User; user: User }>(
    `/users/${username}/follow/`
  );
}
