import { acceptHMRUpdate, defineStore } from "pinia";
import { ref } from "vue";

import useUsersStore from "@/stores/resources/users-store";

const useUserSuggestionStore = defineStore(
  "user-suggestion-store",
  function () {
    const userSuggestionIds = ref<Set<number>>(new Set());

    const userStore = useUsersStore();

    function setUserSuggestionIds(userIds: Array<number>) {
      const sortedIds = [...userIds, ...userSuggestionIds.value];

      console.log({
        sortedIds,
      });

      userSuggestionIds.value = new Set(sortedIds);
    }

    function parseUserSuggestions(users: Array<User>) {
      const userIds = new Set<number>();

      users.forEach((user) => {
        userIds.add(user.id);
      });

      console.log({
        users,
        userIds,
      });

      userStore.parseUsers(users);
      setUserSuggestionIds(Array.from(userIds));
    }

    return {
      userSuggestionIds,
      parseUserSuggestions,
    };
  }
);

export default useUserSuggestionStore;

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useUserSuggestionStore, import.meta.hot)
  );
}
