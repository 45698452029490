import { BOOKMARKS_ROUTE } from "@/router/constants";

const BookmarkApp = () => import("@/apps/BookmarkApp.vue");

export default {
  name: BOOKMARKS_ROUTE,
  path: "/bookmarks",
  component: BookmarkApp,
  meta: { secure: true },
};
