import api from "@/services/api";

interface ChatListResponse {
  user: User;
  chats: Array<Chat>;
}

interface ChatMessageListResponse {
  messages: Array<Message>;
  chat: Chat;
}

interface CreateNewChatResponse {
  users: Array<User>;
  chat: Chat;
}

interface SendMessageToChat {
  message: Message;
  chat: Chat;
}

interface SendMessageToNewChat {
  users: Array<User>;
  message: Message;
  chat: Chat;
}

interface GetChatByUsernameResponse {
  chat: Chat;
  user: User;
}

interface GetChatByIdResponse {
  chat: Chat;
}

export function getChatList() {
  return api.get<ChatListResponse>(`/messaging`);
}

export function getChatMessageList(chatId: number) {
  return api.get<ChatMessageListResponse>(`/messaging/${chatId}/messages`);
}

export function createNewChat() {
  return api.get<CreateNewChatResponse>(`/messaging/new`);
}

export function sendMessageToChat(
  chatId: number,
  text: string,
  photos?: Array<Record<string, string>>
) {
  return api.post<SendMessageToChat>(`/messaging/${chatId}/`, {
    text,
    vapor_messageable_photos: photos,
  });
}

export function sendMessageToNewChat(
  userIds: Array<number>,
  text: string,
  photos?: Array<Record<string, string>>
) {
  return api.post<SendMessageToNewChat>(`/messaging`, {
    userIds,
    text,
    vapor_messageable_photos: photos,
  });
}

export function getChatByUsername(username: string) {
  return api.get<GetChatByUsernameResponse>(`/messaging/@${username}`);
}

export function getChatById(chatId: number) {
  return api.get<GetChatByIdResponse>(`/messaging/${chatId}`);
}
