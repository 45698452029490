export default function usePlatform() {
  const featureSupport = {
    push: "PushManager" in window,
    share: "share" in navigator,
    shareTarget: "shareTarget" in navigator,
    contactPicker: "contacts" in navigator,
    notification: "Notification" in window,
  };

  const isIOS = [
    "iPad Simulator",
    "iPhone Simulator",
    "iPod Simulator",
    "iPad",
    "iPhone",
    "iPod",
  ].includes(navigator.platform);

  const isRunningAsPWA =
    window.matchMedia("(display-mode: standalone)").matches ||
    (navigator as any).standalone;

  const isRunningAsTWA =
    isRunningAsPWA && document.referrer.startsWith("android-app://");

  function hasSupport<T extends keyof typeof featureSupport>(feature: T) {
    return featureSupport[feature];
  }

  return {
    isIOS,
    isRunningAsPWA,
    isRunningAsTWA,
    hasSupport,
  };
}
