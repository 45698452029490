<script lang="ts" setup>
import { computed } from "vue";

import InterfaceDesignToggles from "@/components/InterfaceDesignToggles.vue";

import usePreferences from "@/stores/common/preferences";

const preferencesStore = usePreferences();

function handleDesignToggle(key: string) {
  switch (key) {
    case "paper":
      preferencesStore.togglePaperDesign();
      break;
    case "glass":
      preferencesStore.toggleGlassDesign();
      break;
    case "space":
      preferencesStore.toggleSpacedDesign();
      break;
    case "wire":
      preferencesStore.toggleWiredDesign();
      break;
    case "compactList":
      preferencesStore.toggleCompactListDesign();
      break;
    case "tint":
      preferencesStore.toggleThemeTint();
      break;
  }
}

const designToggles = computed(() => ({
  paper: {
    key: "paper",
    name: "Paper",
    checked: preferencesStore.hasPaperDesign,
  },
  glass: {
    key: "glass",
    name: "Glass",
    checked: preferencesStore.hasGlassDesign,
  },
  wire: {
    key: "wire",
    name: "Wire",
    checked: preferencesStore.hasWiredDesign,
  },
  compactList: {
    key: "compactList",
    name: "Compact",
    checked: preferencesStore.hasCompactListDesign,
  },
  space: {
    key: "space",
    name: "Space",
    checked: preferencesStore.hasSpacedDesign,
  },
  tint: {
    key: "tint",
    name: "Theme tint",
    checked: preferencesStore.hasThemeTint,
  },
}));
</script>

<template>
  <InterfaceDesignToggles
    @toggle-tap="handleDesignToggle"
    :design-toggles="designToggles"
  />
</template>
@/stores/common/preferences
