import { NETWORK_POST_COMPOSE_ROUTE } from "@/router/constants";

const PostComposeApp = () => import("@/apps/PostComposeApp.vue");

export default {
  name: NETWORK_POST_COMPOSE_ROUTE,
  path: "/axis/:slug/compose/",
  component: PostComposeApp,
  meta: { secure: true },
};
