import api from "./api";

export function fetchPostsByTag(tag: string) {
  return api.get<{
    posts: Array<Post>;
    reactions: Array<Reaction>;
    bookmarks: Array<Bookmark>;
    tag: Tag;
  }>(`/tags/${tag}`);
}
