import { acceptHMRUpdate, defineStore } from "pinia";
import { ref } from "vue";

import usePostStore from "./posts-store";

import type { InitialRequestData } from "@/services/app-service";
import { getUserById, getUserByUsername } from "@/services/user-service";

const useUsersStore = defineStore("users", () => {
  const userIdByUsernameMap = ref<Record<string, number>>({});
  const usersById = ref<Record<number, User>>({});

  const postStore = usePostStore();

  function init(data: InitialRequestData) {
    parseUsers(data.resources.users);
  }

  /**
   * Store users by id in JSON object.
   * @param users Users to set.
   */
  function setUsersById(users: Array<User>) {
    for (let i = 0; i < users.length; i++) {
      const user = users[i];
      if (!user) continue;
      usersById.value[user.id] = user;
      userIdByUsernameMap.value[user.username.toLowerCase()] = user.id;
    }
  }

  /**
   *  Parse users and their relationships.
   * @param users Users to parse.
   */
  function parseUsers(users: Array<User>) {
    const usersWithPosts = users
      .filter(
        (user) =>
          user !== undefined &&
          "latest_post" in user &&
          user.latest_post !== null
      )
      .map((user) => {
        const post = user.latest_post as Post;
        delete user.latest_post;
        return { ...user, latest_post: post };
      });

    if (usersWithPosts.length === 0) {
      postStore.parsePosts(
        usersWithPosts.map((user) => user.latest_post as Post)
      );
    }

    setUsersById(users);
    return users;
  }

  async function fetchUserByUsername(username: string) {
    try {
      const { data } = await getUserByUsername(username);
      parseUsers([data.user]);
      return data.user;
    } catch (e) {
      console.log(e);
    }
  }

  async function fetchUserById(userId: number) {
    try {
      const { data } = await getUserById(userId);
      parseUsers([data.user]);
      return data.user;
    } catch (e) {
      console.log(e);
    }
  }

  function $reset() {
    userIdByUsernameMap.value = {};
    usersById.value = {};
  }

  return {
    usersById,
    setUsersById,
    parseUsers,
    fetchUserById,
    fetchUserByUsername,
    userIdByUsernameMap,
    init,
    $reset,
  };
});

export default useUsersStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUsersStore, import.meta.hot));
}
