<script lang="ts" setup>
import { ref, watch } from "vue";

const props = defineProps<PropTypes>();

const selectedIndex = ref(0);

defineExpose({
  onKeyDown,
});

function upHandler() {
  selectedIndex.value =
    (selectedIndex.value + props.items.length - 1) % props.items.length;
}

function downHandler() {
  selectedIndex.value = (selectedIndex.value + 1) % props.items.length;
}

function spaceHandler() {
  selectItem(0);
}

function selectItem(index: number) {
  const item = props.items[index];

  if (item) {
    props.command({ id: item.username });
  }
}

function enterHandler() {
  selectItem(selectedIndex.value);
}

function onKeyDown({ event }: { event: KeyboardEvent }) {
  console.log("KeyDown");
  if (event.key === "ArrowUp") {
    upHandler();
    return true;
  }

  if (event.key === "ArrowDown") {
    downHandler();
    return true;
  }

  if (event.key === "Enter") {
    enterHandler();
    return true;
  }

  if (event.key === " ") {
    spaceHandler();
    return true;
  }

  return false;
}

watch(
  () => props.items,
  () => (selectedIndex.value = 0)
);

type PropTypes = {
  items: Array<User>;
  command: (object: { id: string }) => void;
};
</script>

<template>
  <div class="items">
    <button
      class="flex flex-row item"
      :class="{ 'is-selected': index === selectedIndex }"
      v-for="(item, index) in items"
      :key="index"
      @click="selectItem(index)"
    >
      <div class="flex flex-row">
        <!-- <UserIcon class="flex flex-shrink mr-2" :user="item" size="md" /> -->
        <div class="flex flex-col">
          <span class="font-bold">{{ item.name }}</span>
          <span class="text-grey-500">@{{ item.username }}</span>
        </div>
      </div>
    </button>
  </div>
</template>

<style lang="postcss" scoped>
.items {
  position: relative;
  border-radius: 0.25rem;
  background: white;
  color: rgba(black, 0.8);
  overflow: hidden;
  font-size: 0.9rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.item {
  display: block;
  width: 100%;
  text-align: left;
  background: transparent;
  border: none;
  padding: 0.2rem 0.5rem;

  &.is-selected,
  &:hover {
    color: #a975ff;
    background: rgba(#a975ff, 0.1);
  }
}
</style>
