import { defineStore, acceptHMRUpdate } from "pinia";
import { ref } from "vue";

import useUserStore from "@/stores/resources/users-store";

import useChatStore from "./chats-store";

import { sendChatMessage } from "@/services/chat-service";
import {
  getMessageThreadForUser,
  sendMessage,
} from "@/services/messages-service";
import { sortIterable } from "@/utils/sorting";

const useMessagesStore = defineStore("messages", () => {
  const messagesById = ref<Map<number, Message>>(new Map());
  const messsageIdsByThread = ref<Map<string, Set<number>>>(new Map());
  const chatMessageIdsByChatId = ref<Map<number, Set<number>>>(new Map());

  const userStore = useUserStore();
  const chatStore = useChatStore();

  /**
   * Set messages in messageById map.
   * @param messages - Messages to be processed
   */
  function setMessagesById(messages: Message[]): void {
    messages.map((message) => messagesById.value.set(message.id, message));
  }

  /**
   * Store utility function to process and set a collection of message ids
   * under a given DM thread.
   * @param messages - Messages to be processed.
   * @param username - username/thread to set the Set of ids under.
   */
  function setMessageIdsByThread(messages: Message[], username: string) {
    // New buckets
    const newThreadMessageMap = new Set<number>();
    const newMessageByIdMap = new Map();

    // Sort and add to new buckets
    sortIterable(messages, "desc", "created_at").forEach(function (message) {
      newThreadMessageMap.add(message.id as number);
      newMessageByIdMap.set(message.id, message);
    });

    // merge old and new buckets
    if (!messsageIdsByThread.value.has(username))
      messsageIdsByThread.value.set(username, new Set());

    messsageIdsByThread.value.set(
      username,
      new Set([
        ...newThreadMessageMap,
        ...(messsageIdsByThread.value.get(username) as Set<number>),
      ])
    );

    messagesById.value = new Map(...newMessageByIdMap, ...messagesById.value);
  }

  /**
   * Store utility function to process and set a collection of message ids
   * under a given DM thread.
   * @param messages - Messages to be processed.
   * @param username - username/thread to set the Set of ids under.
   */
  function setMessageIdsByChat(messages: Message[], chatId: number) {
    // New buckets
    const newChatMessageMap = new Set<number>();
    const newMessageByIdMap = new Map();

    // Sort and add to new buckets
    sortIterable(messages, "desc", "created_at").forEach(function (message) {
      newChatMessageMap.add(message.id as number);
      newMessageByIdMap.set(message.id, message);
    });

    // merge old and new buckets
    if (!chatMessageIdsByChatId.value.has(chatId))
      chatMessageIdsByChatId.value.set(chatId, new Set());

    chatMessageIdsByChatId.value.set(
      chatId,
      new Set([
        ...newChatMessageMap,
        ...(chatMessageIdsByChatId.value.get(chatId) as Set<number>),
      ])
    );

    messagesById.value = new Map(...newMessageByIdMap, ...messagesById.value);
  }

  /**
   * Get thread of messages for a given user.
   * @param username - Username used to get thread
   */
  async function fetchThreadMessages(
    username: string,
    beforeMessageId?: number
  ) {
    const { data: messages } = await getMessageThreadForUser(
      username,
      beforeMessageId
    );
    setMessagesById(messages);
    setMessageIdsByThread(messages, username);
  }

  function parseMessages(messages: Message[]) {
    const usersMap = new Map<number, User>();
    const parsedMessages = messages.map((message) => {
      if (message.to_user) {
        usersMap.set(message.to_user.id, message.to_user);
        delete message.to_user;
      }
      if (message.from_user) {
        usersMap.set(message.from_user.id, message.from_user);
        delete message.from_user;
      }

      return message;
    });

    userStore.parseUsers(Array.from(usersMap.values()));
    setMessagesById(parsedMessages);
  }

  function parseMessagesForChat(messages: Message[], chatId: number) {
    const usersMap = new Map<number, User>();
    const parsedMessages = messages.map((message) => {
      if (message.to_user) {
        usersMap.set(message.to_user.id, message.to_user);
        delete message.to_user;
      }
      if (message.from_user) {
        usersMap.set(message.from_user.id, message.from_user);
        delete message.from_user;
      }

      return message;
    });

    userStore.parseUsers(Array.from(usersMap.values()));
    setMessagesById(parsedMessages);
    setMessageIdsByChat(parsedMessages, chatId);
  }

  async function sendMessageToUser(
    username: string,
    text: string,
    photos?: Array<Record<string, string>>
  ) {
    try {
      const { data } = await sendMessage(username, text, photos);

      setMessagesById([data.message]);
      // setMessageIdsByThread([data.message], username);
      chatStore.parseChats([data.chat]);
    } catch (e) {
      console.log("Unable to send message");
    }
  }

  async function sendMessageToChat(
    chatId: number,
    text: string,
    photos?: Array<Record<string, string>>
  ) {
    try {
      const { data } = await sendChatMessage(chatId, text, photos);

      setMessagesById([data.message]);
      chatStore.parseChats([data.chat]);
    } catch (e) {
      console.log(`Unable to Send message to chat ${chatId}`);
    }
  }

  return {
    messagesById,
    fetchThreadMessages,
    messsageIdsByThread,
    sendMessageToUser,
    sendMessageToChat,
    setMessagesById,
    parseMessages,
    parseMessagesForChat,
    chatMessageIdsByChatId,
  };
});

export default useMessagesStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMessagesStore, import.meta.hot));
}
