import useBookmarkStore from "@/stores/resources/bookmarks-store";
import usePostStore from "@/stores/resources/posts-store";
import useReactionStore from "@/stores/resources/reactions-store";

export default function usePostActions() {
  const bookmarksStore = useBookmarkStore();
  const reactionStore = useReactionStore();
  const postStore = usePostStore();

  return {
    createPost: postStore.createPost,
    createPostThread: postStore.createPostThread,
    removePost: postStore.removePost,
    toggleBookmark: (postId: number) => bookmarksStore.toggleBookmark(postId),
    toggleReaction: (postId: number) => reactionStore.toggleReaction(postId),
  };
}
