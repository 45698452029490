import { EXPLORE_ROUTE } from "@/router/constants";

const ExploreApp = () => import("@/apps/ExploreApp.vue");

export default {
  name: EXPLORE_ROUTE,
  path: "/explore",
  component: ExploreApp,
  meta: { secure: true },
};
