import { acceptHMRUpdate, defineStore } from "pinia";
import { ref } from "vue";

import useAuth from "@/stores/common/auth";
import useUsersStore from "@/stores/resources/users-store";

import {
  followUserByUsername,
  unfollowUserByUsername,
} from "@/services/follow-service";

const useFollowStore = defineStore("follow", () => {
  const auth = useAuth();
  const userStore = useUsersStore();

  const userFollowersByUsername = ref<Record<string, Set<string>>>({}); // userId -> followedUserIds
  const usersFollowingsByUsername = ref<Record<string, Set<string>>>({}); // userId -> followersUserIds

  /**
   * Parse followers for current user.
   * @param users Followers to be parsed.
   * @description
   *  This function will go through each user do the following:
   * 1. Add the current user to the user's followers.
   * 2. Add the user to the current user's followings.
   * @example
   * const followers = [{ username: "user1" }, { username: "user2" }];
   * parseFollowers(followers: User[])
   * userFollowersByUsername.value['user1'] // Set { 'current_user' }
   * userFollowersByUsername.value['current_user'] // Set { 'user1' }
   */
  function parseFollowers(users: User[]) {
    users.forEach((user) => {
      const followers = (userFollowersByUsername.value[auth.user.username] ||=
        new Set());
      followers.add(user.username);
      userFollowersByUsername.value[auth.user.username] = followers;

      const followings = (usersFollowingsByUsername.value[user.username] ||=
        new Set());
      followings.add(auth.user.username);
      usersFollowingsByUsername.value[user.username] = followings;
    });
  }

  /**
   * Parse followings for current user.
   * @param users Followings to be parsed.
   * @description
   * This function will go through each user do the following:
   * 1. Add the current user to the user's followings.
   * 2. Add the user to the current user's followers.
   * @example
   * const followings = [{ username: "user1" }, { username: "user2" }];
   * parseFollowings(followings: User[])
   * usersFollowingsByUsername.value['user1'] // Set { 'current_user' }
   * userFollowersByUsername.value['current_user'] // Set { 'user1' }
   */
  function parseFollowings(users: User[]) {
    users.forEach((user) => {
      const followings = (usersFollowingsByUsername.value[
        auth.user.username
      ] ||= new Set());
      followings.add(user.username);
      usersFollowingsByUsername.value[auth.user.username] = followings;
      const followers = (userFollowersByUsername.value[user.username] ||=
        new Set());
      followers.add(auth.user.username);
      userFollowersByUsername.value[user.username] = followers;
    });
  }

  /**
   * Getter utility function to get followers of a user from store.
   * @param username Username of the user to get followers for
   * @returns Collection of usernames of users following the user
   *
   * @description
   * This function will get a user's followers from the store by doing the following:
   * 1. Search username in other user's followings.
   * 2. Search other user's username in username's followings.
   * 3. Return a collection of usernames of users following the user.
   */
  function getFollowersByUsername(username: string) {
    const followerUsernames =
      userFollowersByUsername.value[username] || new Set();

    for (const key in usersFollowingsByUsername.value) {
      if (usersFollowingsByUsername.value[key].has(username)) {
        followerUsernames.add(key);
      }
    }

    return Array.from(followerUsernames || []) as Array<string>;
  }

  /**
   * Getter utility function to get followings of a user from store.
   * @param username Username of the user to get followings for
   * @returns Collection of usernames of users followed by the user
   *
   * @description
   * This function will fetch a user's followings from the store by doing the following:
   * 1. Search username in other user's followings.
   * 2. Search other user's username in username's followings.
   * 3. Return a collection of usernames of users followed by the user.
   */
  function getFollowingsByUsername(username: string) {
    const followingUsernames = new Set();
    for (const key in usersFollowingsByUsername.value) {
      if (userFollowersByUsername.value[key]?.has(username)) {
        followingUsernames.add(key);
      }
    }

    if (username in usersFollowingsByUsername.value) {
      usersFollowingsByUsername.value[username].forEach((u) => {
        followingUsernames.add(u);
      });
    }

    return Array.from(followingUsernames || []) as Array<string>;
  }

  /**
   * Follow user by username.
   * @param username Username of the user to follow.
   *
   * @description
   * This function will follow a user by username by doing the following:
   * 1. Send a request to the server to follow the user.
   * 2. Add the user to the current user's followings.
   * 3. Add the current user to the user's followers.
   * 4. Parse the user and current user.
   */
  async function followUser(username: string) {
    try {
      const {
        data: { follower, following },
      } = await followUserByUsername(username);

      usersFollowingsByUsername.value[auth.user.username] ||= new Set();
      usersFollowingsByUsername.value[auth.user.username].add(
        following.username
      );

      usersFollowingsByUsername.value[following.username] ||= new Set();
      usersFollowingsByUsername.value[following.username].add(
        auth.user.username
      );

      userStore.parseUsers([follower, following] as Array<User>);
    } catch (e) {
      console.log(e);
    }
  }

  async function unfollowUser(username: string) {
    try {
      const {
        data: { unfollowed, user },
      } = await unfollowUserByUsername(username);
      const currentFollowers =
        userFollowersByUsername.value[unfollowed.username];
      const currentFollowings = usersFollowingsByUsername.value[user.username];

      currentFollowers?.delete(user.username);
      currentFollowings?.delete(unfollowed.username);

      userFollowersByUsername.value[unfollowed.username] = currentFollowers;
      usersFollowingsByUsername.value[user.username] = currentFollowings;
    } catch (e) {
      console.log(e);
    }
  }

  return {
    followUser,
    unfollowUser,
    getFollowersByUsername,
    getFollowingsByUsername,
    userFollowersByUsername,
    usersFollowingsByUsername,
    parseFollowers,
    parseFollowings,
  };
});

export default useFollowStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFollowStore, import.meta.hot));
}
