import { POST_REPLY_COMPOSE_ROUTE } from "@/router/constants";

const PostComposeApp = () => import("@/apps/PostComposeApp.vue");

export default {
  name: POST_REPLY_COMPOSE_ROUTE,
  path: "/post/:postId/compose-reply/",
  component: PostComposeApp,
  meta: { secure: true },
};
