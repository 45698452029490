<script setup lang="ts">
import { ref, useAttrs, onMounted, onBeforeUnmount, watch } from "vue";

type PropType = {
  root?: boolean | string | HTMLElement;
  target?: string;
  hasPageLoaded?: boolean;
  hasPageTopObserver?: boolean;
  hasPageBottomObserver?: boolean;
};

const props = withDefaults(defineProps<PropType>(), {
  root: false,
  hasPageLoaded: false,
});

const emit = defineEmits(["hit-top", "hit-bottom", "target"]);

const attrs = useAttrs();

let topObserver: IntersectionObserver;
let bottomObserver: IntersectionObserver;
let customObserver: IntersectionObserver;

const top = ref<HTMLElement>();
const bottom = ref<HTMLElement>();

function handleTopHit(entries: IntersectionObserverEntry[]) {
  if (entries[0].isIntersecting && props.hasPageLoaded) {
    emit("hit-top");
  }
}

function handleBottomHit(entries: IntersectionObserverEntry[]) {
  if (entries[0].isIntersecting && props.hasPageLoaded) {
    emit("hit-bottom");
  }
}

function targetHit(entries: IntersectionObserverEntry[]) {
  if (entries[0].isIntersecting) {
    emit("target");
  }
}

function registerObservers() {
  console.log({
    attrs,
    props,
  });
  if (props.hasPageTopObserver) {
    topObserver = new IntersectionObserver(handleTopHit, {
      rootMargin: "0px",
      threshold: 1.0,
    });

    topObserver.observe(top.value as HTMLElement);
  }

  if (props.hasPageBottomObserver) {
    bottomObserver = new IntersectionObserver(handleBottomHit, {
      rootMargin: "0px",
      threshold: 1.0,
    });

    bottomObserver.observe(bottom.value as HTMLElement);
  }

  if (attrs["onTarget"] && props.target) {
    const targetElement = document.querySelector(props.target);

    if (targetElement) {
      customObserver = new IntersectionObserver(targetHit, {
        rootMargin: "0px",
        threshold: 1.0,
      });

      customObserver.observe(targetElement);
    }
  }
}

watch(
  () => props.hasPageLoaded,
  (val) => {
    if (val) registerObservers();
    else unregisterObservers();
  }
);

function unregisterObservers() {
  if (topObserver) {
    topObserver.disconnect();
  }

  if (bottomObserver) {
    bottomObserver.disconnect();
  }

  if (customObserver) {
    customObserver.disconnect();
  }
}

onMounted(() => {
  if (props.hasPageLoaded) registerObservers();
});

onBeforeUnmount(() => {
  unregisterObservers();
});
</script>

<template>
  <div
    ref="top"
    id="infinite-scroll-top"
    class="absolute h-[25%] bottom-0 w-full z-[-1]"
  ></div>
  <slot></slot>
  <div ref="bottom" id="infinite-scroll-bottom"></div>
</template>
