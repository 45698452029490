import axios from "axios";

import api from "@/services/api";
import {
  CSRF_CHECK_ENDPOINT,
  LOGIN_ENDPOINT,
  LOGOUT_ENDPOINT,
  ME_ENDPOINT,
} from "@/services/constants";

export async function getAuthCookie() {
  return axios.get(CSRF_CHECK_ENDPOINT);
}

export async function me() {
  return api.get<User>(ME_ENDPOINT);
}

export async function authCheck() {
  return axios.get<{ authenticated: boolean }>("/auth-check");
}

export async function login(user: string, password: string, remember = false) {
  await authCheck();
  return axios.post(LOGIN_ENDPOINT, {
    email: user,
    password,
    remember,
  });
}

export async function logout() {
  return await axios.post(LOGOUT_ENDPOINT);
}
