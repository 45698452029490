import type { genericTypedObject } from "@/types/appTypes";
import { isObject } from "@/utils/validation";

type SortOrder = "desc" | "asc";

type DeepObject<T = unknown> = {
  [key: string]: T | DeepObject<T>;
};

function deepObjectValue<T>(
  obj: DeepObject<T>,
  objectProperties: Array<string>
) {
  return objectProperties.reduce<T | DeepObject<T>>(function (acc, property) {
    if (isObject(acc)) {
      const nestedValue = acc[property as keyof typeof acc]; // Not even ChatGPT was able to make this more legible.
      if (nestedValue !== undefined) {
        return nestedValue as T | DeepObject<T>;
      }
    }
    return acc;
  }, obj);
}

export function sortIterable<T extends number | string>(
  collection: Array<T>,
  order: SortOrder
): Array<T>;

export function sortIterable<T extends number | string>(
  collection: Set<T>,
  order: SortOrder
): Set<T>;

export function sortIterable<T extends DeepObject>(
  collection: Array<T>,
  order: SortOrder,
  sortBy: keyof T
): Array<T>;

export function sortIterable<T extends DeepObject>(
  collection: Set<T>,
  order: SortOrder,
  sortBy: keyof T
): Set<T>;

export function sortIterable<T extends DeepObject>(
  collection: Array<T>,
  order: SortOrder,
  sortBy: Array<string>
): Array<T>;

export function sortIterable<T extends DeepObject>(
  collection: Set<T>,
  order: SortOrder,
  sortBy: Array<string>
): Set<T>;

export function sortIterable<T extends DeepObject>(
  collection: Array<T>,
  order: SortOrder,
  sortBy: string
): Array<T>;

export function sortIterable<T extends DeepObject>(
  collection: Set<T>,
  order: SortOrder,
  sortBy: string
): Set<T>;

/**
 * Sorts a Set or Array of objects or primitives.
 * @param collection  A Set or Array of objects or primitives to sort.
 * @param order The order to sort the collection in. Defaults to "desc".
 * @param sortBy The property to sort by. Defaults to undefined.
 * @returns A sorted Set or Array. If the collection is a Set, the return value will be a Set. If the collection is an Array, the return value will be an Array.
 *
 * @example
 * // Iterable only
 * sortIterable([1,2,3]) // [3,2,1]
 *
 * // Iterable + order
 * sortIterable([3,2,1], 'asc') // [1,2,3]
 *
 * // Iterable of objects + order + sortBy string
 * sortIterable([{id: 2}, {id: 3}, {id: 1}], 'asc', 'id') // [{id: 1}, {id: 2}, {id: 3}]
 *
 * // Iterable of objects (with deeply nested properties to sort by) + order + sortBy array (or string with dot separates properties)
 * sortIterable(postsWithLatestReply, 'desc', 'latestReply.id') // It will return posts array sorted by id found in its latestReply property
 *  * sortIterable(postsWithLatestReply, 'desc', ['latestReply','id']) // Same as above.
 *
 */
export function sortIterable<T extends Array<unknown>, K extends keyof T[0]>(
  collection: T,
  order: SortOrder = "desc",
  sortBy?: string | Array<string> | K | Array<K>
): T {
  let newCollection = collection;
  let workingArr: Array<(typeof collection)[0]> = [];

  if (collection instanceof Set) {
    workingArr = Array.from(collection);
  }

  if (Array.isArray(collection)) {
    workingArr = collection;
  }

  workingArr.sort((a, b) => {
    // if sortBy has a "." split it and find the deep value of this objects to compare.
    if (
      sortBy &&
      ((sortBy as string).includes(".") || Array.isArray(sortBy)) &&
      isObject(a) &&
      isObject(b)
    ) {
      let sortByProps = [];
      if (Array.isArray(sortBy)) {
        sortByProps = sortBy;
      } else {
        sortByProps = (sortBy as string).split(".");
      }

      return deepObjectValue(a as DeepObject, sortByProps as string[]) &&
        deepObjectValue(a as DeepObject, sortByProps as string[])
        ? 1
        : -1;
    }

    if (
      sortBy &&
      !Array.isArray(sortBy) &&
      isObject(a) &&
      isObject(b) &&
      (sortBy as string) in (a as object) &&
      (sortBy as string) in (b as object)
    ) {
      if (
        (a as genericTypedObject<string>)[sortBy as string] <
        (b as genericTypedObject<string>)[sortBy as string]
      )
        return order === "desc" ? 1 : -1;
      if (
        (a as genericTypedObject<string>)[sortBy as string] >
        (b as genericTypedObject<string>)[sortBy as string]
      )
        return order === "desc" ? -1 : 1;
    } else {
      if ((a as string) < (b as string)) return order === "desc" ? 1 : -1;
      if ((a as string) > (b as string)) return order === "desc" ? -1 : 1;
    }
    return 0;
  });

  newCollection = workingArr as unknown as T; // FIXME: This is a hack.

  if (collection instanceof Set) {
    newCollection = new Set(workingArr) as unknown as T; // FIXME: This is a hack.
  }

  return newCollection;
}
