<script lang="ts" setup>
import { computed } from "vue";

const props = withDefaults(defineProps<PropTypes>(), {
  text: "",
});

const transformedText = computed(() => ({
  template: props.text
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/{/g, "&lcub;")
    .replace(/}/g, "&rcub;")
    .replace(/\[/g, "&lbrack")
    .replace(/\]/g, "&rbrack")
    .replace(/\n/g, "<br/>")
    .replace(/!!([^!]+)!!/g, '<span class="italic">$1</span>')
    .replace(/__([^_]+)__/g, '<span class="underline">$1</span>')
    .replace(/~~([^~]+)~~/g, '<span class="line-through">$1</span>')
    .replace(/\*\*([^*]+)\*\*/g, '<span class="font-bold">$1</span>')
    .replace(
      /``([^`]+)``/g,
      '<span class="border rounded-md py-0.5 px-1 border-theme-500 bg-theme-100 text-theme-500">$1</span>'
    )
    .replace(
      /(([a-zA-Z0-9]+:\/\/)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\.[A-Za-z]{2,4})(:[0-9]+)?((\/.{0,14}).*)?)/g,
      '<a href="$1" class="font-semibold text-theme-600 dark:text-theme-300 tint-rate-75" v-on:click.stop rel="noreferrer" target="_blank">$2$4$5</a>'
    )
    .replace(
      /#([a-zA-Z0-9_]+)/g,
      `<RouterLink v-on:click.stop v-bind:to="{name: 'tag', params: { tagId: '$1' }}" class="font-semibold text-theme-600 dark:text-theme-300 tint-rate-75">#$1</RouterLink>`
    )
    .replace(
      // /(?<!\w)@([a-zA-Z0-9_]+)(?!\w|$)/g,
      /@(\w+)\b/g,
      `<RouterLink v-on:click.stop v-bind:to="{name: 'profile', params: { username: '$1'}}" class="font-semibold text-theme-600 dark:text-theme-300 tint-rate-75">@$1</RouterLink>`
    ),
  name: "PostTextContent",
}));

type PropTypes = {
  text: string;
};
</script>

<template>
  <Component :is="transformedText" />
</template>
