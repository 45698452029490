export const CSRF_CHECK_ENDPOINT = "/sanctum/csrf-cookie";
export const LOGIN_ENDPOINT = "/acceso";
export const ME_ENDPOINT = "/me";
export const LOGOUT_ENDPOINT = "/logout";

export const MESSAGES_ENDPOINT = "/messages";

export const POSTS_ENDPOINT = "/posts";

export const POST_BOOKMARKS_ENDPOINT = "/bookmarks";
