import { acceptHMRUpdate, defineStore } from "pinia";
import { ref } from "vue";

import useMessagesStore from "@/stores/resources/messages-store";
import useUserStore from "@/stores/resources/users-store";

import useOtherUser from "@/hooks/useOtherUser";

const useChatStore = defineStore("chats", function () {
  type excludesChatKeys = "last_message" | "members";

  const chatsById = ref<Map<number, Omit<Chat, excludesChatKeys>>>(new Map());
  const chatUserIdsByChatId = ref<Map<number, Set<number>>>(new Map());
  const chatMessageIdsByChatId = ref<Map<number, Set<number>>>(new Map());

  const chatWithUsernameToChatId = ref<Map<string, number>>(new Map());

  const messageStore = useMessagesStore();
  const userStore = useUserStore();

  function setChatsById(chats: Array<Chat>) {
    const newChatsById = new Map();
    chats.forEach((chat) => newChatsById.set(chat.id, chat));
    chatsById.value = new Map([...chatsById.value, ...newChatsById]);
  }

  function setUsernamesByChatId(chatId: number, userIds: Array<number>) {
    if (!chatUserIdsByChatId.value.has(chatId)) {
      chatUserIdsByChatId.value.set(chatId, new Set(userIds));
      return;
    }

    const oldSet = chatUserIdsByChatId.value.get(chatId);
    chatUserIdsByChatId.value.set(
      chatId,
      new Set([...(oldSet as Set<number>), ...userIds])
    );
  }

  function parseChats(chats: Array<Chat>) {
    const parsedChats = chats.map((chat) => {
      if (chat.dm_user_ids) {
        const [user1, user2] = chat.dm_user_ids
          .split("_")
          .map((id) => userStore.usersById[parseInt(id)]);

        const chatWithUser = useOtherUser(user1, user2);
        chatWithUsernameToChatId.value.set(
          chatWithUser.username,
          chatWithUser.id
        );
      }

      if (chat.members && chat.members.length > 0) {
        setUsernamesByChatId(
          chat.id,
          chat?.members.map((m) => (m.user as User).id)
        );
        delete chat.members;
      }

      if (chat.last_message) {
        messageStore.parseMessages([chat.last_message]);
        delete chat.last_message;
      }

      return chat;
    });

    setChatsById(parsedChats);
  }

  function convertUsernameToChatId(username: string) {
    return chatWithUsernameToChatId.value.get(username);
  }

  return {
    chatsById,
    parseChats,
    convertUsernameToChatId,
    chatWithUsernameToChatId,
  };
});

export default useChatStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useChatStore, import.meta.hot));
}
