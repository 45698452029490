<script lang="ts" setup>
import { Button } from "@allaxis/ui";

import usePreferences from "@/stores/common/preferences";

const preferencesStore = usePreferences();
</script>
<template>
  <Button
    v-if="preferencesStore.hasPermissionForNotifications !== 'granted'"
    type="primary"
    text="Enable notifications"
    :disabled="preferencesStore.hasPermissionForNotifications !== 'default'"
    themed
    @click="preferencesStore.askForNotificationPermission"
  />
  <Button
    v-else
    class=""
    type="default"
    themed
    disabled
    text="Notifications enabled"
  />
</template>
@/stores/common/preferences
