import api from "./api";

export function getReactions(postId: number) {
  return api.get(`/posts/${postId}/reactions`);
}

export function addReaction(postId: number) {
  return api.post(`/posts/${postId}/reactions`);
}

export function removeReaction(postId: number, reaction: number) {
  return api.delete(`/posts/${postId}/reactions/${reaction}`);
}
