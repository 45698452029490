<script lang="ts" setup>
import { Card, Button } from "@allaxis/ui";
import { ref } from "vue";

import DesignPreferences from "@/containers/DesignPreferences.vue";
import NotificationPermissionButton from "@/containers/NotificationPermissionButton.vue";

const emit = defineEmits(["complete"]);

const TRANSITION_LEFT = "transition-left";
const TRANSITION_RIGHT = "transition-right";
const SLIDE_COUNT = 3;

const selectedSlide = ref(0);
const transitionName = ref(TRANSITION_RIGHT);

function goToNextSlide() {
  transitionName.value = TRANSITION_RIGHT;
  if (SLIDE_COUNT - 1 > selectedSlide.value) selectedSlide.value++;
}

function goToPreviousSlide() {
  transitionName.value = TRANSITION_LEFT;
  if (selectedSlide.value > 0) selectedSlide.value--;
}

function finish() {
  emit("complete");
}
</script>

<template>
  <Card
    class="w-full flex-grow sm:flex-grow-[unset] max-w-screen-sm sm:h-[40rem]"
  >
    <div
      id="onboarding-intro"
      class="flex flex-col items-center justify-around h-full p-10 pb-10-safe pt-10-safe"
      v-if="selectedSlide === 0"
    >
      <h4
        class="text-3xl text-dynamic-neutral-dark dark:text-dynamic-neutral-light"
      >
        Welcome to Allaxis
      </h4>
      <p
        class="text-base leading-loose text-center text-dynamic-neutral-700 dark:text-dynamic-neutral-200"
      >
        A social network for all types of groups, communities and social
        collectives.
      </p>

      <p
        class="text-base leading-loose text-center text-dynamic-neutral-700 dark:text-dynamic-neutral-200"
      >
        ... or just social networking in general...
      </p>
      <Button
        @click="goToNextSlide"
        text="Press to continue..."
        type="primary"
        size="xl"
        themed
      />
    </div>
    <div
      v-if="selectedSlide === 1"
      id="onboarding-ui-preferences"
      class="flex flex-col items-center justify-around h-full p-10"
    >
      <h4
        class="text-3xl text-dynamic-neutral-dark dark:text-dynamic-neutral-light"
      >
        UI Settings
      </h4>

      <div class="flex flex-col items-center justify-center">
        <body class="h-48 overflow-hidden rounded-md shadow-inner">
          <section
            class="w-32 text-sm section text-dynamic-neutral-700 dark:text-dynamic-neutral-200"
          >
            <div class="p-2 ui-item">UI Item</div>
            <ul class="ui-list">
              <li class="p-2 rounded-md">List item</li>
              <li class="p-2 rounded-md">List item</li>
            </ul>
          </section>
        </body>
      </div>

      <div class="w-full">
        <DesignPreferences class="w-full max-w-3xl" />
      </div>
      <Button
        @click="goToNextSlide"
        text="Next"
        size="xl"
        type="primary"
        themed
      />
    </div>
    <div
      v-if="selectedSlide === 2"
      id="onboarding-notification-preferences"
      class="flex flex-col items-center justify-around h-full p-10"
    >
      <h4
        class="text-3xl text-dynamic-neutral-dark dark:text-dynamic-neutral-light"
      >
        Permissions
      </h4>

      <p
        class="text-base leading-loose text-center text-dynamic-neutral-700 dark:text-dynamic-neutral-200"
      >
        Native app notifications.
      </p>

      <NotificationPermissionButton />

      <Button @click="finish" text="Finish" size="xl" type="primary" themed />
    </div>
  </Card>
</template>
