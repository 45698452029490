import { ref } from "vue";

import callOptional from "@/utils/callOptional";

function usePageState() {
  const hasRanOnce = ref(false);
  const loading = ref(false);
  const errorWhileLoading = ref(false);

  function clearLoading() {
    loading.value = false;
    errorWhileLoading.value = false;
  }

  function setLoading(isLoading: boolean) {
    loading.value = isLoading;
    console.log({
      loading: loading.value,
    });
  }

  function setErrorWhileLoading(hasErrorWhileLoading: boolean) {
    errorWhileLoading.value = hasErrorWhileLoading;
  }

  /**
   * Utility function to handle page loading.
   * @param {Function} options.setup       Function to be called before attempting to fetch data.
   * @param {Function} options.fetch       Function to call to fetch data
   * @param {Function} options.error       Function to call for error handling
   * @param {Function} options.cleanup     Function to call as clean up function (finally of try and catch)
   *
   * @returns {void}
   */
  async function fetchPageData(options: {
    fetch: () => void | Promise<void>;
    error?: () => void | Promise<void>;
    cleanup?: () => void | Promise<void>;
    setup?: () => void | Promise<void>;
  }) {
    setLoading(true);
    callOptional(options.setup);
    try {
      await callOptional(options.fetch);
    } catch (e) {
      setErrorWhileLoading(true);
      callOptional(options.error);
    } finally {
      setLoading(false);
      callOptional(options.cleanup);
    }
  }

  return {
    loading,
    hasRanOnce,
    errorWhileLoading,
    clearLoading,
    setLoading,
    setErrorWhileLoading,
    fetchPageData,
  };
}

export default usePageState;
