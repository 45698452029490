import { acceptHMRUpdate, defineStore } from "pinia";
import { computed } from "vue";

import useNotificationStore from "@/stores/resources/notifications-store";
import usePostStore from "@/stores/resources/posts-store";
import useUsersStore from "@/stores/resources/users-store";

import usePageState from "@/hooks/usePageState";

import { loadNotificationsPageData } from "@/services/page-service";

const useNotificationPageStore = defineStore("notificationPage", function () {
  const { loading, errorWhileLoading, fetchPageData } = usePageState();

  const notificationStore = useNotificationStore();
  const postStore = usePostStore();
  const userStore = useUsersStore();

  const unreadNotifications = computed(() =>
    Array.from(notificationStore.notificationIds || [])
      .map(
        (id) => notificationStore.notificationsById.get(id) as UserNotification
      )
      .filter((n) => n?.read_at === null)
      .sort((a, b) =>
        (a.created_at as string) < (b.created_at as string) ? 1 : -1
      )
  );
  const readNotifications = computed(() =>
    Array.from(notificationStore.notificationIds || [])
      .map(
        (id) => notificationStore.notificationsById.get(id) as UserNotification
      )
      .filter((n) => n?.read_at !== null)
      .sort((a, b) =>
        (a.created_at as string) < (b.created_at as string) ? 1 : -1
      )
  );

  async function loadPageData() {
    await fetchPageData({
      async fetch() {
        await new Promise((resolve) => setTimeout(() => resolve(0), 1000));

        const { data } = await loadNotificationsPageData();

        console.log({
          DEBUG_NOTIFICATIONS: data,
        });

        userStore.parseUsers(data.userDependencies);
        postStore.parsePosts(data.postDependencies);

        notificationStore.parseNotificatiosFromRequestData(data);
      },
    });
  }

  return {
    loading,
    errorWhileLoading,
    loadPageData,

    readNotifications,
    unreadNotifications,

    clearCurrentNotifications:
      notificationStore.clearCurrentUnreadNotifications,
    clearAllNotifications: notificationStore.clearAllUnreadNotifications,
  };
});

export default useNotificationPageStore;

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useNotificationPageStore, import.meta.hot)
  );
}
