<template>
  <div class="flex p-4 space-x-4 rounded-md animate-pulse">
    <div
      class="w-10 h-10 rounded-full bg-dynamic-neutral-300 dark:bg-dynamic-neutral-700"
    />
    <div class="flex-1 py-1 space-y-4">
      <div class="space-y-2">
        <div
          class="w-5/6 h-4 rounded bg-dynamic-neutral-300 dark:bg-dynamic-neutral-700"
        />
        <div
          class="h-4 rounded bg-dynamic-neutral-300 dark:bg-dynamic-neutral-700"
        />
      </div>
    </div>
  </div>
</template>
