import { ROUTES } from "@/router";
const ProfilePostFeed = () => import("@/partials/ProfilePostFeed.vue");
const ProfileApp = () => import("@/apps/ProfileApp.vue");
const ProfileStories = () => import("@/partials/ProfileStories.vue");
const ProfileStoryDetail = () => import("@/partials/ProfileStoryDetail.vue");
// const ProfileActivities = () => import("@/partials/ProfileActivities.vue");

export default {
  name: ROUTES.PROFILE,
  path: "/@:username",
  component: ProfileApp,
  meta: { secure: true },
  children: [
    {
      name: ROUTES.PROFILE_FEED,
      path: "",
      component: ProfilePostFeed,
    },

    {
      name: ROUTES.PROFILE_STORIES,
      path: "stories",
      component: ProfileStories,
    },

    // {
    //   name: ROUTES.PROFILE_ACTIVITIES,
    //   path: "activities",
    //   component: ProfileActivities,
    // },

    {
      name: ROUTES.PROFILE_STORIES_DETAIL,
      path: "stories/:storyId",
      component: ProfileStoryDetail,
    },

    // {
    //   path: "stories",
    //   redirect: () => ({ name: ROUTES.PROFILE_STORIES }),
    // },
  ],
  redirect: () => ({
    name: ROUTES.PROFILE_FEED,
  }),
};
