import { useShare } from "@vueuse/core";
import { defineStore, acceptHMRUpdate } from "pinia";
import { ref } from "vue";

import type { ContextMenuConfig } from "@/types/appTypes";

const useUiStore = defineStore("ui", () => {
  const sidebarOpen = ref(false);
  const userBottomSheetOpen = ref(false);
  const postComposeModalOpen = ref(false);
  const messageComposeModalOpen = ref(false);
  const contactModalOpen = ref(false);
  const contextMenuConfig = ref<ContextMenuConfig | null>({
    lists: [
      {
        items: [
          {
            label: "Pin",
            icon: "far fa-thumbtack",
            action: () => console.log("DEMO PIN"),
          },

          {
            label: "Delete",
            icon: "far fa-trash-can",
            action: () => console.log("DELETE PIN"),
          },
        ],
      },

      {
        items: [
          {
            label: "Share",
            icon: "far fa-arrow-up-from-bracket",
            action: () => {
              console.log("DEMO SHARE");

              useShare({
                title: "VueUse",
                text: "VueUse is a collection of essential Vue Composition APIs.",
                url: "https://vueuse.org/",
              });
            },
          },

          {
            label: "Bookmark",
            icon: "far fa-bookmark",
            action: () => {
              console.log("DEMO Bookmark");
            },
          },

          {
            label: "Share via...",
            icon: "far fa-share-alt",
            action: () => {
              console.log("DEMO Share via...");
            },
          },
        ],
      },

      {
        direction: "horizontal",
        items: [
          {
            label: "Share",
            icon: "far fa-arrow-up-from-bracket",
            action: () => {
              console.log("DEMO SHARE");

              useShare({
                title: "VueUse",
                text: "VueUse is a collection of essential Vue Composition APIs.",
                url: "https://vueuse.org/",
              });
            },
          },

          {
            label: "Bookmark",
            icon: "far fa-bookmark",
            action: () => {
              console.log("DEMO Bookmark");
            },
          },

          {
            label: "Share via...",
            icon: "far fa-share-alt",
            action: () => {
              console.log("DEMO Share via...");
            },
          },

          {
            label: "Bookmark",
            icon: "far fa-bookmark",
            action: () => {
              console.log("DEMO Bookmark");
            },
          },

          {
            label: "Share via...",
            icon: "far fa-share-alt",
            action: () => {
              console.log("DEMO Share via...");
            },
          },
        ],
      },
    ],
  });

  const openSidebar = () => (sidebarOpen.value = true);
  const closeSidebar = () => (sidebarOpen.value = false);
  const toggleSidebar = () => (sidebarOpen.value = !sidebarOpen.value);

  const openUserBottomSheet = () => (userBottomSheetOpen.value = true);
  const closeUserBottomSheet = () => (userBottomSheetOpen.value = false);
  const toggleUserBottomSheet = () =>
    (userBottomSheetOpen.value = !userBottomSheetOpen.value);

  const openPostComposeModal = () => (postComposeModalOpen.value = true);
  const closePostComposeModal = () => (postComposeModalOpen.value = false);

  const openMessageComposeModal = () => (messageComposeModalOpen.value = true);
  const closeMessageComposeModal = () =>
    (messageComposeModalOpen.value = false);

  const openContactModal = () => (contactModalOpen.value = true);
  const closeContactModal = () => (contactModalOpen.value = false);

  const openContextMenu = (config: ContextMenuConfig) =>
    (contextMenuConfig.value = config);
  const closeContextMenu = () => (contextMenuConfig.value = null);

  return {
    sidebarOpen,
    openSidebar,
    closeSidebar,

    userBottomSheetOpen,
    openUserBottomSheet,
    closeUserBottomSheet,
    toggleUserBottomSheet,

    postComposeModalOpen,
    openPostComposeModal,
    closePostComposeModal,

    messageComposeModalOpen,
    openMessageComposeModal,
    closeMessageComposeModal,

    contactModalOpen,
    openContactModal,
    closeContactModal,

    toggleSidebar,
    contextMenuConfig,
    openContextMenu,
    closeContextMenu,
  };
});

export default useUiStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUiStore, import.meta.hot));
}
