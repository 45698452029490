<script lang="ts" setup>
import { ref } from "vue";

import TipTapEditor from "@/components/TipTapEditor.vue";

import useMessageActions from "@/hooks/useMessageActions";

const emit = defineEmits<EmitTypes>();

const textModel = ref("");

const { handleMessageCreation } = useMessageActions();

function handleClosetap(event: MouseEvent | TouchEvent) {
  event.stopPropagation();
  emit("close");
}

type EmitTypes = {
  (e: "close"): void;
};
</script>

<template>
  <div
    @click="handleClosetap"
    class="fixed top-0 left-0 z-50 flex flex-col items-center justify-start w-full h-full glass-blur bg-dynamic-dark/20 md:hidden"
  >
    <div
      class="relative flex flex-col items-center justify-center w-full px-2 top-2-safe"
    >
      <div
        @click.stop
        class="w-full max-w-screen-sm bg-dynamic-neutral-light rounded-md ui-item dark:bg-dynamic-neutral-900"
      >
        <div
          class="flex flex-row justify-between p-2 border-b border-dynamic-neutral-200 dark:border-dynamic-neutral-700 dark:text-dynamic-neutral-200 text-dynamic-neutral-800"
        >
          <span class="flex-grow p-2"></span>
          <h4 class="p-2 font-semibold">New Message</h4>
          <span class="flex items-center justify-end flex-grow p-2">
            <button @click="handleClosetap">
              <i class="far fa-xmark"></i>
            </button>
          </span>
        </div>

        <TipTapEditor
          v-model="textModel"
          use-large-textarea
          @send="
            () =>
              handleMessageCreation({
                text: textModel,
                onSuccess: () => emit('close'),
              })
          "
          class="w-full p-2 py-2"
          button-text="Send"
        />
      </div>
    </div>
  </div>
</template>
