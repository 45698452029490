import api from "@/services/api";

interface ChatResponseData {
  chat: Chat;
  messages: Array<Message>;
  members: Array<ChatMember>;
}

export function getChats() {
  return api.get<Array<Chat>>("/chats");
}

export function getChatById(chatId: number) {
  return api.get<ChatResponseData>(`/chats/${chatId}`);
}

export function getChatByUsername(username: string) {
  return api.get<ChatResponseData>(`/chats/@${username}`);
}

export function sendChatMessage(
  chatId: number,
  text: string,
  photos?: Array<Record<string, string>>
) {
  return api.post<{ message: Message; chat: Chat }>(
    `/chat/${chatId}/messages`,
    {
      text,
      vapor_messageable_photos: photos,
    }
  );
}
