import { MESSAGES_ENDPOINT } from "./constants";

import api from "@/services/api";
import type { IThread } from "@/types/appTypes";

export async function getMessageThreads() {
  return api.get<IThread[]>(`${MESSAGES_ENDPOINT}`);
}

/**
 *
 * @param username - Username with whom current has this conversation/thread with.
 * @param lastMessageId - Last loaded message from which to start the query. Starts from the very last message this parameter is not supplied.
 * @returns Messages in a DM thread with a user.
 *
 * @example
 * // Get last 100 messages, starting with the last one...
 * const { data: messages } = await getMessageThreadForUser('johndoe');
 * // Get last 100 messages, starting from a given message—in this case, starting with message 11 to 111
 * const { data: messages } = await getMessageThreadForUser('johndoe', 10);
 */
export async function getMessageThreadForUser(
  username: string,
  beforeMessageId?: number
) {
  const query = beforeMessageId ? `?beforeMessageId=${beforeMessageId}` : "";
  return api.get<Message[]>(`${MESSAGES_ENDPOINT}/${username}${query}`);
}

export function sendMessage(
  username: string,
  text: string,
  photos?: Array<Record<string, string>>
) {
  return api.post<{ message: Message; chat: Chat }>(
    `${MESSAGES_ENDPOINT}/${username}`,
    { text, vapor_messageable_photos: photos }
  );
}
