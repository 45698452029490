import { useRoute } from "vue-router";

import useMessagesPageStore from "@/stores/activities/messages-page-store";
import useChatStore from "@/stores/resources/new-chat-store";
import useUsersStore from "@/stores/resources/users-store";

import { ROUTES } from "@/router";
import type {
  DirectMessageThread,
  GroupMessageThread,
} from "@/types/routeTypes";

export default function useMessageActions() {
  const messagePageStore = useMessagesPageStore();
  const chatStore = useChatStore();
  const userStore = useUsersStore();

  const route = useRoute();

  async function handleMessageCreation({
    text,
    photos,
    onSuccess,
  }: {
    text: string;
    photos?: Array<Record<string, string>>;
    onSuccess?: () => void;
  }) {
    const username = (route as DirectMessageThread).params.username;

    try {
      switch (route.name) {
        case ROUTES.PROFILE_FEED:
        case ROUTES.MESSAGE_THREAD:
          {
            const usernameLower = username.toLowerCase();
            const chatId = chatStore.usernameChatIdMap.get(usernameLower);

            if (chatId) {
              messagePageStore.sendMessage(chatId as number, text, photos);
            } else if (userStore.userIdByUsernameMap[usernameLower]) {
              const userId = userStore.userIdByUsernameMap[usernameLower];
              messagePageStore.sendMessageToNewChat([userId], text, photos);
            } else {
              await userStore.fetchUserByUsername(usernameLower);
              const userId = userStore.userIdByUsernameMap[usernameLower];
              messagePageStore.sendMessageToNewChat([userId], text, photos);
            }
          }
          break;
        default:
          await messagePageStore.sendMessage(
            (route as GroupMessageThread).params.chatId,
            text
          );
          break;
      }
    } catch (e) {
      console.log("POST COMPOSE MODAL: Something went wrong posting", e);
    } finally {
      if (typeof onSuccess === "function") onSuccess();
    }
  }

  return {
    handleMessageCreation,
  };
}
