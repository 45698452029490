import api from "./api";

export type InitialRequestData = {
  resources: {
    posts: Array<Post>;
    reactions: Array<Reaction>;
    bookmarks: Array<Bookmark>;
    users: Array<User>;
    notifications: Array<UserNotification>;
    suggestions: Array<User>;
    chats: Array<Chat>;
    messages: Array<Message>;
    networks: Array<Network>;
    follows: Array<User>;
    followers: Array<User>;
  };
  activities: {
    home: {
      post_ids: Array<number>;
    };
    explore: {
      user_ids: Array<number>;
    };
    notifications: {
      unreadNotification: number;
      notification_ids: Array<string>;
    };
    messaging: {
      chat_ids: Array<number>;
    };
    profile: {
      post_ids: Array<number>;
    };
    bookmarks: {
      post_ids: Array<number>;
    };
  };
};

export function loadAppInitialState() {
  return api.get<InitialRequestData>("/initial-state");
}
