import api from "@/services/api";

export interface IPageData {
  unreadNotificationCount?: number;
  notifications?: Array<UserNotification>;
  following?: Array<User>;
  followers?: Array<User>;
}
interface IHomePageData extends IPageData {
  posts: Array<Post>;
  reactions: Array<Reaction>;
  bookmarks: Array<Bookmark>;
}

interface IProfilePageData extends IPageData {
  user: User;
  posts: Array<Post>;
  follows: Array<Follow>;
  reactions: Array<Reaction>;
  bookmarks: Array<Bookmark>;
  userFollowsCurrentUser: boolean;
  currentUserFollowsUser: boolean;
}

interface IPreferencesPageData extends IPageData {
  user: User;
}

interface IBookmarksPageData extends IPageData {
  bookmarks: Array<Bookmark>;
}

interface IPostDetailsPageData extends IPageData {
  post: Post;
  replies: Array<Post>;
  reactions: Array<Reaction>;
  bookmarks: Array<Bookmark>;
}

interface IPeoplePageData extends IPageData {
  followings: Array<User>;
  followers: Array<User>;
}

interface IExplorePageData extends IPageData {
  users: Array<User>;
  following: Array<User>;
  followers: Array<User>;
}

interface IMessagesPageData extends IPageData {
  chats: Array<Chat>;
  user: User;
}

interface INotificatonsPageData extends IPageData {
  notifications: Array<UserNotification>;
  userDependencies: Array<User>;
  postDependencies: Array<Post>;
}

interface INetworkPageData extends IPageData {
  network: Network;
  posts: Array<Post>;
  reactions: Array<Reaction>;
  bookmarks: Array<Bookmark>;
}

export function loadHomePageData(
  options: {
    before_post_id?: number;
    after_post_id?: number;
  } = {}
) {
  return api.get<IHomePageData>("/page/home", {
    params: options,
  });
}

export function loadProfilePageData(username: string) {
  return api.get<IProfilePageData>(`/page/profile/${username}`);
}

export function loadPreferencesPageData() {
  return api.get<IPreferencesPageData>("/page/preferences");
}

export function loadBookmarksPageData() {
  return api.get<IBookmarksPageData>("/page/bookmarks");
}

export function loadPostDetailsPageData(postId: number) {
  return api.get<IPostDetailsPageData>(`/page/post/${postId}`);
}

export function loadSettingPageData() {
  return api.get<IPreferencesPageData>("/page/settings");
}

export function loadPeoplePageData() {
  return api.get<IPeoplePageData>("/page/people");
}

export function loadExplorePageData() {
  return api.get<IExplorePageData>("/page/explore");
}

export function loadMessagesPageData() {
  return api.get<IMessagesPageData>("/page/messages");
}

export function loadNotificationsPageData() {
  return api.get<INotificatonsPageData>("/page/notifications");
}

export function loadNetworkPageData(slug: string) {
  return api.get<INetworkPageData>(`/page/network/${slug}`);
}
