<script lang="ts" setup>
import { useSlots } from "vue";
import { useRouter } from "vue-router";

type PropTypes = {
  routeNavigationOptions: object | null;
};

withDefaults(defineProps<PropTypes>(), {
  routeNavigationOptions: null,
});

const router = useRouter();
const slots = useSlots();

function navigate(routeOptions: object | null) {
  if (routeOptions) router.push(routeOptions);
}
</script>

<template>
  <div
    @click.stop="navigate(routeNavigationOptions)"
    :class="{
      'cursor-pointer hover:bg-dynamic-neutral-100 dark:hover:bg-dynamic-neutral-800':
        !!routeNavigationOptions,
    }"
    class="flex flex-row items-stretch pt-2 pb-4 transition-colors duration-150"
  >
    <div v-if="slots.left" class="flex flex-col flex-shrink">
      <div class="flex items-center justify-center w-12 h-6 pt-1 bg-red">
        <slot name="left" />
      </div>
    </div>
    <div
      :class="{ 'pl-3': !slots.left, 'pr-3': !slots.right }"
      class="flex flex-col justify-center flex-grow align-middle"
    >
      <slot></slot>
    </div>
    <div
      v-if="slots.right"
      class="flex flex-col items-start justify-center flex-shrink"
    >
      <div class="flex items-center justify-center w-12 h-6 bg-red">
        <slot name="right" />
      </div>
    </div>
  </div>
</template>
