import MessagesApp from "@/apps/MessagesApp.vue";
import DirectMessageThread from "@/partials/DirectMessageThread.vue";
import GroupMessageThread from "@/partials/GroupMessageThread.vue";
import {
  MESSAGES_ROUTE,
  MESSAGE_GROUP_THREAD_ROUTE,
  MESSAGE_THREAD_ROUTE,
} from "@/router/constants";

export default {
  name: MESSAGES_ROUTE,
  path: "/messages",
  component: MessagesApp,
  meta: { secure: true },
  children: [
    {
      name: MESSAGE_THREAD_ROUTE,
      path: "@:username",
      component: DirectMessageThread,
    },
    {
      name: MESSAGE_GROUP_THREAD_ROUTE,
      path: ":chatId",
      component: GroupMessageThread,
    },
  ],
};
