import { SETTINGS_ROUTE } from "@/router/constants";

const SettingApp = () => import("@/apps/SettingsApp.vue");

export default {
  name: SETTINGS_ROUTE,
  path: "/settings",
  component: SettingApp,
  meta: { secure: true },
};
