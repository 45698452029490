import api from "@/services/api";
import { POSTS_ENDPOINT, POST_BOOKMARKS_ENDPOINT } from "@/services/constants";

export function getAllBookmarks() {
  return api.get<Array<Bookmark>>(`${POST_BOOKMARKS_ENDPOINT}`);
}

export function addPostBookmark(postId: number) {
  return api.post<Bookmark>(
    `${POSTS_ENDPOINT}/${postId}${POST_BOOKMARKS_ENDPOINT}`
  );
}

export function removePostBookmark(postId: number, bookmarkId: number) {
  return api.delete<Array<Post>>(
    `${POSTS_ENDPOINT}/${postId}${POST_BOOKMARKS_ENDPOINT}/${bookmarkId}`
  );
}
