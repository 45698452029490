<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import { onBeforeRouteUpdate, useRoute } from "vue-router";

import MessageThreadWindow from "@/components/MessageThreadWindow.vue";

import useAuthStore from "@/stores/common/auth";
import useMessagesStore from "@/stores/resources/messages-store";
import useChatStore from "@/stores/resources/new-chat-store";
import useUsersStore from "@/stores/resources/users-store";
import useMessagesPageStore from "@/stores/activities/messages-page-store";

import { ROUTES } from "@/router";
import type { GroupMessageThread } from "@/types/routeTypes";

const route = useRoute() as GroupMessageThread;
const messageStore = useMessagesStore();
const authStore = useAuthStore();
const userStore = useUsersStore();
const chatStore = useChatStore();

const messagePageStore = useMessagesPageStore();

const chatId = computed(() => route.params.chatId as unknown as string);

const chat = computed(() => {
  return chatStore.chatsById.get(parseInt(chatId.value));
});

const messages = computed(() => {
  console.log({
    chatId: chatId.value,
    messagesMap: chatStore.messageIdsByChatId,
    chatMessagesMap: chatStore.messageIdsByChatId.get(parseInt(chatId.value)),
  });
  return Array.from(
    chatStore.messageIdsByChatId.get(parseInt(chatId.value)) || []
  )
    .map((id) => messageStore.messagesById.get(id) as Message)
    .filter((m) => m !== undefined)
    .map((m) => {
      return {
        ...m,
        from_user: userStore.usersById[m.from_user_id] ?? {
          acronym: "Unknown",
          name: "unknown",
          username: "unknown",
          id: 0,
        },
      };
    });
});

const messageContainerElement = ref();

onMounted(async () => {
  if (route.name === ROUTES.MESSAGE_GROUP_THREAD && route.params.chatId) {
    await messagePageStore.loadGroupChat(parseInt(chatId.value));
    messageContainerElement.value &&
      messageContainerElement.value.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
  }
});

onBeforeRouteUpdate(async (to, from) => {
  if (
    [to.name, from.name].includes(ROUTES.MESSAGE_GROUP_THREAD) &&
    to.params.chatId !== from.params.chatId
  ) {
    await userStore.fetchUserByUsername(route.params.username as string);
    await messageStore.fetchThreadMessages(to.params.username as string);
    await messagePageStore.loadGroupChat(parseInt(chatId.value));
    messageContainerElement.value &&
      messageContainerElement.value.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
  }
});
</script>

<template>
  <MessageThreadWindow
    :current-user="authStore.user"
    :title="chat?.title || ''"
    :photo-icon-text="chat?.title?.charAt(0) ?? ''"
    :messages="messages"
    :is-loading-messages="messagePageStore.loading"
    :window-ref="messageContainerElement"
    @message="
      (payload) => messageStore.sendMessageToChat(route.params.chatId, payload.text )
    "
  />
</template>
