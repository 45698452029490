<script lang="ts" setup>
const version = import.meta.env.VITE_APP_VERSION;
const year = new Date().getFullYear();
</script>

<template>
  <div>
    <div
      class="px-2 py-1 text-xs text-dynamic-neutral-500 dark:text-dynamic-neutral-300"
    >
      v{{ version }}
    </div>
    <!-- <nav class="flex flex-wrap text-xs">
      <div class="px-2 py-1">
        <a
          href="javascript:void(0)"
          title="About Allaxis"
          class="text-xs leading-6 text-dynamic-neutral-500 dark:text-dynamic-neutral-300 hover:text-dynamic-neutral-900 dark:hover:text-dynamic-neutral-100"
          >About</a
        >
      </div>
      <div class="px-2 py-1">
        <a
          href="javascript"
          title="Allaxis Careers"
          class="text-xs leading-6 text-dynamic-neutral-500 dark:text-dynamic-neutral-300 hover:text-dynamic-neutral-900 dark:hover:text-dynamic-neutral-100"
          >Careers</a
        >
      </div>
      <div class="px-2 py-1">
        <a
          href="javascript"
          title="Allaxis Press"
          class="text-xs leading-6 text-dynamic-neutral-500 dark:text-dynamic-neutral-300 hover:text-dynamic-neutral-900 dark:hover:text-dynamic-neutral-100"
          >Press</a
        >
      </div>
      <div class="px-2 py-1">
        <a
          href="javascript"
          title="Allaxis Accessibility"
          class="text-xs leading-6 text-dynamic-neutral-500 dark:text-dynamic-neutral-300 hover:text-dynamic-neutral-900 dark:hover:text-dynamic-neutral-100"
          >Accessibility</a
        >
      </div>
      <div class="px-2 py-1">
        <a
          href="javascript"
          title="Allaxis Partners"
          class="text-xs leading-6 text-dynamic-neutral-500 dark:text-dynamic-neutral-300 hover:text-dynamic-neutral-900 dark:hover:text-dynamic-neutral-100"
          >Partners</a
        >
      </div>
    </nav> -->
    <div class="mt-2">
      <p
        class="text-xs leading-6 text-dynamic-neutral-400 dark:text-dynamic-neutral-200"
      >
        &copy; {{ year }} Allaxis, Inc. All rights reserved.
      </p>
    </div>
  </div>
</template>
