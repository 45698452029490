import { TAG_ROUTE } from "@/router/constants";

const TagApp = () => import("@/apps/TagApp.vue");

export default {
  name: TAG_ROUTE,
  path: "/tags/:tagId",
  component: TagApp,
  meta: { secure: true },
};
