import { acceptHMRUpdate, defineStore } from "pinia";
import { ref } from "vue";

import useAuth from "@/stores/common/auth";
import usePostStore from "@/stores/resources/posts-store";

import type { InitialRequestData } from "@/services/app-service";
import { addReaction, removeReaction } from "@/services/reactions-service";

const useReactionsStore = defineStore("reactions", () => {
  const reactionsById = ref<Map<number, Reaction>>(new Map());
  const reactionIdsByPostId = ref<Record<number, Set<number>>>({});
  const userRectionsByPostId = ref<Map<number, number>>(new Map());

  const auth = useAuth();
  const postStore = usePostStore();

  function init(data: InitialRequestData) {
    parseReactions(data.resources.reactions);
  }

  function setReactionsById(reactions: Array<Reaction>) {
    reactions.forEach((reaction) => {
      if (reaction.reactionable_type === "App\\Models\\Post") {
        if (!reactionIdsByPostId.value[reaction.reactionable_id])
          reactionIdsByPostId.value[reaction.reactionable_id] = new Set();
        reactionIdsByPostId.value[reaction.reactionable_id].add(reaction.id);
      }

      if (auth.user && reaction.user_id === auth.user.id) {
        userRectionsByPostId.value.set(reaction.reactionable_id, reaction.id);
      }

      reactionsById.value.set(reaction.id, reaction);
    });
  }

  function parseReactions(reactions: Array<Reaction>) {
    setReactionsById(reactions);
    return reactions;
  }

  /**
   * Add a bookmark to a post.
   * @param postId
   */
  async function reactToPost(postId: number) {
    const {
      data: { reaction, post },
    } = await addReaction(postId);
    setReactionsById([reaction]);
    postStore.parsePosts([post]);
  }

  /**
   * Unbookmark a post.
   * @param postId Post id to unbookmark.
   * @param reactionId Bookmark id to delete.
   */
  async function unreactToPost(postId: number, reactionId: number) {
    const {
      status,
      data: { post },
    } = await removeReaction(postId, reactionId);
    if (status === 200) {
      reactionIdsByPostId.value[postId].delete(reactionId);
      userRectionsByPostId.value.delete(postId);
      reactionsById.value.delete(postId);
      postStore.parsePosts([post]);
    }
  }

  /**
   * Toggle a bookmark on a post.
   * @param postId Post id to toggle bookmark.
   */
  async function toggleReaction(postId: number) {
    const postWithReaction = userRectionsByPostId.value.get(postId);
    if (postWithReaction) {
      await unreactToPost(postId, postWithReaction as number);
    } else {
      await reactToPost(postId);
    }
  }

  return {
    toggleReaction,
    reactionsById,
    reactionIdsByPostId,
    parseReactions,
    userRectionsByPostId,
    init,
  };
});

export default useReactionsStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useReactionsStore, import.meta.hot));
}
