import api from "@/services/api";

export function getUserByUsername(username: string) {
  return api.get<{ user: User }>(`/users/${username}/`);
}

export function getUserById(userId: number) {
  return api.get<{ user: User }>(`/users/byid/${userId}`);
}

export async function updateUserByUsername(
  username: string,
  data: Pick<User, "name" | "username" | "phone" | "email" | "tagline">
) {
  return api.patch<{ user: User }>(`/users/${username}/`, data);
}
