<script lang="ts" setup>
import { useLocalStorage } from "@vueuse/core";
import { useRouter } from "vue-router";

import OnboardingSlider from "@/components/OnboardingSlider.vue";

import { ROUTES } from "@/router";

const router = useRouter();

function onComplete() {
  localStorage.setItem("onboarding-complete", "true");
  router.push({
    name: ROUTES.LOGIN,
  });
}
</script>
<template>
  <div class="pt-0-safe pb-0-safe">
    <section class="flex flex-col items-center justify-center h-screen section">
      <OnboardingSlider @complete="onComplete" class="ui-item" />
    </section>
  </div>
</template>
