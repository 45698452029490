import { acceptHMRUpdate, defineStore } from "pinia";
import { ref } from "vue";

import useAuth from "@/stores/common/auth";
import useMessagesStore from "@/stores/resources/messages-store";
import useUserStore from "@/stores/resources/users-store";

import useUiNotifications from "@/hooks/useUiNotifications";

import {
  getChatById,
  getChatList,
  getChatByUsername,
  getChatMessageList,
  sendMessageToChat,
  sendMessageToNewChat,
} from "@/services/messaging-service";

const useChatStore = defineStore("newChats", () => {
  const { notify } = useUiNotifications();

  // #region Dependencies
  const authStore = useAuth();
  const userStore = useUserStore();
  const messageStore = useMessagesStore();
  // #endregion

  // #region State

  // Chat maps
  const usernameChatIdMap = ref<Map<string, number>>(new Map());
  const chatIdUsernameMap = ref<Map<number, string>>(new Map());

  const chatsById = ref<Map<number, Chat>>(new Map());
  const userIdsByChatId = ref<Map<number, Set<number>>>(new Map());
  const messageIdsByChatId = ref<Map<number, Set<number>>>(new Map());
  // #endregion

  // #region Setters

  function setChatsById(chats: Array<Chat>) {
    const chatsByIdMap = new Map();

    chats.forEach((chat) => {
      chatsByIdMap.set(chat.id, chat);
    });

    chatsById.value = new Map([...chatsById.value, ...chatsByIdMap]);
  }

  function setMessageIdsbyChatId(chatId: number, messageIds: Array<number>) {
    ensureMessageIdSetExistsForChat(chatId);
    messageIdsByChatId.value.set(
      chatId,
      new Set([
        ...(messageIdsByChatId.value.get(chatId) as Set<number>).values(),
        ...messageIds,
      ])
    );
  }

  function setUserIdsByChatId(chatId: number, userIds: Array<number>) {
    ensureUserIdSetExistForChat(chatId);
    userIdsByChatId.value.set(
      chatId,
      new Set([
        ...(userIdsByChatId.value.get(chatId) as Set<number>),
        ...userIds,
      ])
    );
  }

  // #endregion

  // #region Utility functions

  function setKvChatIdAndUsernameMaps(chatId: number, username: string) {
    usernameChatIdMap.value.set(username.toLowerCase(), chatId);
    chatIdUsernameMap.value.set(chatId, username.toLowerCase());
  }

  function ensureUserIdSetExistForChat(chatId: number) {
    if (userIdsByChatId.value.has(chatId)) return;
    userIdsByChatId.value.set(chatId, new Set());
  }

  function ensureMessageIdSetExistsForChat(chatId: number) {
    if (messageIdsByChatId.value.has(chatId)) return;
    messageIdsByChatId.value.set(chatId, new Set());
  }

  // #endregion

  // #region Actions
  async function sendMessage(
    chatId: number,
    text: string,
    photos?: Array<Record<string, string>>
  ) {
    try {
      const { data } = await sendMessageToChat(chatId, text, photos);

      parseAndAssociateMessages(chatId, [data.message]);
      parseChats([data.chat]);
    } catch (e) {
      notify({
        title: "Message Error",
        text: "Error sending message",
        type: "error",
        duration: 2000,
      });
      console.log(
        `Something went wrong while sending message to group chat ${chatId}`
      );
    }
  }

  async function sendFirstChatMessage(
    userIds: number[],
    text: string,
    photos?: Array<Record<string, string>>
  ) {
    try {
      const { data } = await sendMessageToNewChat(userIds, text, photos);

      parseAndAssociateMessages(data.chat.id, [data.message]);
      parseChats([data.chat]);
    } catch (e) {
      console.log(
        `Something went wrong while sending first message to users with ids ${userIds.join(
          ","
        )}`
      );
    }
  }

  async function getChatMessages(chatId: number) {
    try {
      const { data } = await getChatMessageList(chatId);

      parseChats([data.chat]);
      messageStore.parseMessages(data.messages);

      setMessageIdsbyChatId(
        chatId,
        data.messages.map((m) => m.id)
      );
    } catch (e) {
      console.log(`Error fetching messages for chat Id ${chatId}`, e);
    }
  }

  async function getChats() {
    try {
      const { data } = await getChatList();

      userStore.parseUsers([data.user]);
      parseChats(data.chats);
    } catch (e) {
      console.log(`Error getting chat list.`, e);
    }
  }

  async function getChat(chatId: number) {
    try {
      const { data } = await getChatById(chatId);
      parseChats([data.chat]);
    } catch (e) {
      console.log(`Something went wrong while getting chat id ${chatId}`);
    }
  }

  async function getDirectChat(username: string) {
    try {
      const { data } = await getChatByUsername(username);
      if (data.user) userStore.parseUsers([data.user]);
      if (data.chat) parseChats([data.chat]);
    } catch (e) {
      console.log(
        `Something went wrong while getting chat for user @${username}`
      );
    }
  }

  // #endregion

  function parseChats(chats: Array<Chat>) {
    const parsedChats: Array<Chat> = [];
    const parsedMessages: Array<Message> = [];
    const parsedUsers: Array<User> = [];

    const currentUser = authStore.user;
    chats.forEach((chat) => {
      let directChatWithUsername;
      if (chat.members?.length) {
        const chatMembers = chat.members
          .map((m) => m.user)
          .filter((u) => u !== undefined && u !== null) as Array<User>;

        parsedUsers.push(...chatMembers);

        const chatMemberIds = chatMembers.map((u) => u.id);

        if (chat.dm_user_ids && !directChatWithUsername) {
          const [user1, user2] = chatMembers;
          directChatWithUsername = user1.username;
          if (user1.id === currentUser.id && user2.id === currentUser.id) {
            directChatWithUsername = currentUser.username;
          } else if (user1.id === currentUser.id) {
            directChatWithUsername = user2.username;
          }
        }

        setUserIdsByChatId(chat.id, chatMemberIds);

        delete chat.members;
      }

      if (chat.last_message) {
        parsedMessages.push(chat.last_message);
        setUserIdsByChatId(chat.id, [chat.last_message.id]);
        delete chat.last_message;
      }

      if (directChatWithUsername) {
        setKvChatIdAndUsernameMaps(chat.id, directChatWithUsername);
      }

      parsedChats.push(chat);
    });

    // Betcha the cyclomatic complexity of the this function is nuts. Feel free to fix.
    // TODO: Break up this function as it is beyond complex.

    // These have to be done in this order...
    messageStore.parseMessages(parsedMessages);
    userStore.parseUsers(parsedUsers);
    setChatsById(parsedChats);
  }

  function parseAndAssociateMessages(chatId: number, messages: Array<Message>) {
    setMessageIdsbyChatId(
      chatId,
      messages.map((m) => m.id)
    );
    messageStore.parseMessages(messages);
  }

  return {
    parseChats,
    chatsById,

    sendMessage,
    sendFirstChatMessage,
    getChatMessages,
    getChats,
    getChat,
    getDirectChat,

    usernameChatIdMap,
    chatIdUsernameMap,

    messageIdsByChatId,

    setMessageIdsbyChatId,
  };
});

export default useChatStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useChatStore, import.meta.hot));
}
