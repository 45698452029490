<script lang="ts" setup>
/* eslint-disable  @typescript-eslint/no-explicit-any */
import { ProfilePhoto, Button } from "@allaxis/ui";
import CharacterCount from "@tiptap/extension-character-count";
import Document from "@tiptap/extension-document";
import History from "@tiptap/extension-history";
import Mention from "@tiptap/extension-mention";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import {
  Editor,
  EditorContent,
  VueRenderer,
  type JSONContent,
} from "@tiptap/vue-3";
import tippy, { type GetReferenceClientRect } from "tippy.js";
import { onMounted, computed, onBeforeUnmount, ref } from "vue";

import MentionPopupList from "@/components/MentionPopupList.vue";

import useAuthStore from "@/stores/common/auth";

import useAsset from "@/hooks/useAsset";

import api from "@/services/api";

const props = withDefaults(defineProps<PropTypes>(), {
  id: "tip-tap-editor",
  modelValue: "",
  useLargeTextarea: false,
  buttonText: "",
  showPercentageIndicator: false,
  showUserAvatar: false,
  auxAsFileInput: false,
  allowMultipleFiles: false,
  allowedFileTypes: () => [] as Array<AllowedFileTypes>,
  isLoading: false,
  loadingText: "",
});

const emit = defineEmits<EmitTypes>();

const asset = useAsset();

type AllowedFileTypes = "image/jpeg" | "image/jpg" | "image/png" | "image/webp";

type PropTypes = {
  id: string;
  modelValue: string;
  useLargeTextarea?: boolean;
  buttonText?: string;
  showPercentageIndicator?: boolean;
  showUserAvatar?: boolean;
  allowMultipleFiles?: boolean;
  allowedFileTypes?: Array<AllowedFileTypes>;
  fileCameraCapture?: "user" | "environment";
  auxAsFileInput?: boolean;
  isLoading?: boolean;
  loadingText?: string;
};

type EmitTypes = {
  (e: "send"): void;
  (e: "update:modelValue", text: string): void;
  (e: "auxPress", event: Event): void;
};

const editor = ref<Editor | null>(null);
const limit = ref(365);
const mentions = ref<Array<User>>([]);
const loadingMentions = ref(false);
const timeout = ref<number>();

const me = useAuthStore().user;

const percentage = computed(() =>
  Math.round(
    (100 / limit.value) *
      ((editor.value as Editor)
        ? (editor.value as Editor).storage.characterCount.characters()
        : 1)
  )
);

function handleUpdateButton() {
  emit("send");
  (editor.value as Editor).commands.clearContent(true);
}

function handleAux(event: Event) {
  emit("auxPress", event);
}

onMounted(() => {
  (editor.value as Editor) = new Editor({
    content: props.modelValue,
    extensions: [
      Document,
      Paragraph,
      Text,
      History,
      Placeholder.configure(),
      CharacterCount.configure({
        limit: limit.value,
      }),
      Mention.configure({
        HTMLAttributes: {
          class: "mention",
        },
        suggestion: {
          items: ({ query }) => {
            loadingMentions.value = true;
            api
              .get(`/user-typeahead?query=${query}`)
              .then(({ data, status }) => {
                if (status === 200) {
                  loadingMentions.value = true;
                  mentions.value = data.users;
                }
              });

            return mentions.value
              .filter((item) =>
                item.username.toLowerCase().startsWith(query.toLowerCase())
              )
              .slice(0, 5);
          },
          render: () => {
            let component: any;
            let popup: any;

            return {
              onStart: (suggestionProps) => {
                component = new VueRenderer(MentionPopupList, {
                  editor: editor.value as Editor,
                  props: suggestionProps,
                });

                popup = tippy("body", {
                  getReferenceClientRect:
                    suggestionProps?.clientRect as GetReferenceClientRect,
                  appendTo: () => document.body,
                  content: component.element,
                  showOnCreate: true,
                  interactive: true,
                  trigger: "manual",
                  placement: "bottom-start",
                });
              },
              onUpdate(suggestionProps) {
                component.updateProps(suggestionProps);

                popup[0].setProps({
                  getReferenceClientRect: suggestionProps.clientRect,
                });
              },
              onKeyDown(suggestionProps) {
                if (suggestionProps.event.key === "Escape") {
                  popup[0].hide();
                  return true;
                }

                return component.ref?.onKeyDown(suggestionProps);
              },
              onExit() {
                popup[0].destroy();
                component.destroy();
              },
            };
          },
        },
      }),
    ],
    onUpdate: () => {
      emit(
        "update:modelValue",
        (editor.value as Editor)
          .getJSON()
          .content?.map((c: JSONContent) => {
            if (!c.content) return "";
            return c.content
              .map((cp: any) => {
                if (cp.type === "mention") {
                  return "@" + cp.attrs.id;
                }

                if (cp.type !== "text") return "";

                return cp.text;
              })
              .join("");
          })
          .join("\n") as string
      );
    },
    autofocus: true,
  });
});

onBeforeUnmount(() => {
  setTimeout(() => (editor.value as Editor)?.destroy(), 1000);
});
</script>

<template>
  <div class="flex">
    <div class="flex-shrink hidden pr-2 xs:flex">
      <ProfilePhoto
        v-if="showUserAvatar && me"
        size="md"
        shape="circle"
        :placeholder-text="me.acronym"
        :image-url="
          asset(
            'profile/small/' + me.profile_photo?.filename,
            !!me.profile_photo
          )
        "
      />
    </div>
    <div class="flex-grow">
      <div>
        <editor-content
          class="tip-tap-editor"
          :class="{ large: useLargeTextarea }"
          :editor="(editor as Editor)"
        />
      </div>

      <div>
        <slot />
      </div>

      <div class="flex items-center justify-between">
        <div class="flex justify-between">
          <label
            v-if="auxAsFileInput"
            class="p-2 text-lg leading-none rounded-md text-dynamic-neutral-300 hover:text-dynamic-neutral-700 dark:hover:text-dynamic-neutral-300"
            role="button"
          >
            <i class="fas fa-plus-circle"></i>
            <input
              @change="($event) => handleAux($event)"
              class="hidden"
              :multiple="allowMultipleFiles"
              :accept="allowedFileTypes.join(',')"
              :capture="fileCameraCapture"
              type="file"
            />
          </label>

          <button
            v-else
            @click="handleAux"
            class="p-2 text-lg leading-none rounded-md text-dynamic-neutral-300 hover:text-dynamic-neutral-700 dark:hover:text-dynamic-neutral-300"
          >
            <i class="fas fa-plus-circle"></i>
          </button>
        </div>
        <div class="flex justify-between">
          <span
            v-if="props.showPercentageIndicator"
            class="flex items-center justify-between"
          >
            <svg
              height="25"
              width="25"
              viewBox="0 0 20 20"
              class="character-count__graph"
            >
              <circle
                r="10"
                cx="10"
                cy="10"
                fill="currentColor"
                class="text-dynamic-neutral-300 dark:text-dynamic-neutral-700"
              />
              <circle
                :class="{
                  'text-red-500': percentage >= 95,
                  'text-orange-800': percentage < 95 && percentage >= 90,
                  'text-orange-500': percentage < 90 && percentage >= 85,
                  'text-lime-500': percentage < 85 && percentage >= 75,
                  'text-blue-500': percentage < 75,
                }"
                r="5"
                cx="10"
                cy="10"
                fill="transparent"
                stroke="currentColor"
                stroke-width="10"
                :stroke-dasharray="`calc(${percentage} * 31.4 / 100) 31.4`"
                transform="rotate(-90) translate(-20)"
              />
              <circle
                r="6"
                cx="10"
                cy="10"
                class="text-white dark:text-dynamic-neutral-900"
                fill="currentColor"
              />
            </svg>
          </span>

          <Button
            themed
            type="primary"
            :text="props.buttonText"
            @click="handleUpdateButton"
            :loading="props.isLoading"
            :loading-text="props.loadingText"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
.tip-tap-editor {
  .ProseMirror {
    @apply min-h-[2vh] break-all pb-2 max-w-full pl-2 focus:outline-none;
  }

  .ProseMirror.ProseMirror-focused {
    @apply text-dynamic-neutral-800 dark:text-dynamic-neutral-100;
  }

  .large .ProseMirror {
    @apply min-h-[25vh] sm:min-h-[15vh];
  }

  .ProseMirror p:not(.is-editor-empty) {
    @apply text-dynamic-neutral-700 dark:text-dynamic-neutral-300;
  }

  .ProseMirror p.is-editor-empty:first-child::before {
    @apply dark:text-dynamic-neutral-500 text-dynamic-neutral-400;

    content: attr(data-placeholder);
    float: left;
    pointer-events: none;
    height: 0;
  }

  .mention {
    @apply text-theme-accent-500 font-semibold;

    background-color: rgba(#a975ff, 0.1);
    border-radius: 0.3rem;
    padding: 0.1rem 0.3rem;
  }

  .character-count {
    @apply flex justify-end w-full px-2 items-center;
    margin-top: 1rem;
    color: #68cef8;
  }

  .character-count--warning {
    color: #fb5151;
  }

  .character-count__graph {
    margin-right: 0.5rem;
  }

  .character-count__text {
    color: #868e96;
  }
}
</style>
